import headerImg from '../assets/images/Rectangle 1.png'
import logo from '../assets/images/fulllogo_transparent_nobuffer-1 1.png'
import './index.css'
import sec1Img from '../assets/images/icononly_transparent_nobuffer 1.png'
import sec1Ellipses from '../assets/images/Ellipse 1.png'
import sec1Ellipses2 from '../assets/images/Ellipse 1.png'
import sec2Bg from '../assets/images/Rectangle 4.png'
import sec2Img from '../assets/images/SL-101820-36860-13-ai 1.png'
import sec2ImgTxt from '../assets/images/雲端軟件解决方案.png'
import sec2Img1 from '../assets/images/Group 4.png'
import sec2Img2 from '../assets/images/Group 9.png'
import sec2Img11 from '../assets/images/Group 4 (2).png'
import sec2Img22 from '../assets/images/Group 9 (2).png'
import sec3Bg from '../assets/images/Group 48095405.png'
import sec4Bg from '../assets/images/Group 48095409.png'
import sec3Text from '../assets/images/Web 3.0.png'
import sec3Text2 from '../assets/images/text1.png'
import sec3Text3 from '../assets/images/text2.png'
import sec3Text4 from '../assets/images/text3.png'
import sec3Square from '../assets/images/Group 48095396.png'
import secImg2 from '../assets/images/Group 48095424.png'
import secImg3 from '../assets/images/Group 48095426.png'
import secImg4 from '../assets/images/image 1.png'
import secImg5 from '../assets/images/Group 48095425.png'
import secImg6 from '../assets/images/Group 8.png'
import secImg7 from '../assets/images/Group 7.png'
import secImg8 from '../assets/images/Group 6.png'
import sec3Text5 from '../assets/images/text4.png'
import sec3Img9 from '../assets/images/image 15.png'
import sec4BG from '../assets/images/Group 48095406@2x.png'
import sec5Bg from '../assets/images/Desktop - 9.png'
import sec5Img1 from '../assets/images/图层 8@2x.png'
import sec5Img2 from '../assets/images/Group 48095408@2x.png'
import sec5Img3 from '../assets/images/图层 9 1@2x.png'
import sec5Img4 from '../assets/images/图层 2@2x.png'
import sec6Img1 from '../assets/images/section6-1.png'
import sec6Img2 from '../assets/images/section6-2.png'
import sec6Img3 from '../assets/images/section6-3.png'
import sec6Img4 from '../assets/images/section6-4.png'
import sec6Img5 from '../assets/images/section6-5.png'
import sec6Img6 from '../assets/images/section6-6.png'
import sec6Img7 from '../assets/images/typcn：arrow-back@2x.png'
import sec8img1 from '../assets/images/microsoft.png'
import sec8img2 from '../assets/images/image 21@2x.png'
import sec8img3 from '../assets/images/image 22@2x.png'
import sec8img4 from '../assets/images/alibaba.png'
import sec8img5 from '../assets/images/cloudflare.png'
import sec8img6 from '../assets/images/Rectangle 1076@2x.png'
import sec9img1 from '../assets/images/Rectangle 2@2x.png'
import sec10img1 from '../assets/images/fulllogo_transparent_nobuffer-1 3@2x.png'
import menu from '../assets/images/Group 48095403@2x.png'
import close from '../assets/images/Group 48095413@2x.png'
import sec3topimg from '../assets/images/adobestock_568050974.png'
import videoMp4 from '../assets/video.mp4'
import React, {useEffect, useState} from 'react'
import Particles from "react-tsparticles";
import {loadFull} from "tsparticles";
import particlesOptions from './particles.json'
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Mousewheel} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import whatsappIcon from '../assets/icons/WhatsApp.webp'
import gmailIcon from '../assets/icons/gmail.webp'

export default function App() {

    const particlesInit = async (main) => {
        await loadFull(main);
    };


    const Header = () => {

        return <div className={'Navbar wrapper'}>
            <div className={'Navbar wrapper'}>
                <img className={'Navbar_header'} src={headerImg}/>
                <img className={'Navbar_menu'} src={menu} onClick={() => check()}/>
                <img className={'Navbar_logo'} src={logo}/>
            </div>
            <div className="Navbar container nav_content pc">
                <div className='topnav'>
                    <div className={'Navbar_text'} onClick={() => scrollToAnchor('CorporateCulture')}>公司文化</div>
                    <img className={'toplogo'} src={logo}/>
                    <div className={'Navbar_text'} onClick={() => scrollToAnchor('ContactUs')}>聯絡我們</div>
                </div>
                <div className='bottomnav'>
                    <div className={'Navbar_text'} onClick={() => scrollToAnchor('CloudServices')}>雲端科技服務
                    </div>
                    <div className={'Navbar_text'} onClick={() => scrollToAnchor('FundingPlanning')}>資助規劃
                    </div>
                    <div className={'Navbar_text'} onClick={() => scrollToAnchor('ExampleOfScheme')}> 方案例子
                    </div>
                    <div className={'Navbar_text'} onClick={() => scrollToAnchor('InformationTechnology')}>資訊科技知識
                    </div>
                </div>
            </div>
            <div className="Navbar container nav_content h5" style={{zIndex: isShow ? 999 : -1}}>
                <div className={'Navbar_text'} onClick={() => scrollToAnchor('CorporateCulture')}>公司文化</div>
                <div className={'Navbar_text '} onClick={() => scrollToAnchor('CloudServices')}>雲端科技服務
                </div>
                <div className={'Navbar_text'} onClick={() => scrollToAnchor('FundingPlanning')}>資助規劃
                </div>
                <div className={'Navbar_text'} onClick={() => scrollToAnchor('ExampleOfScheme')}> 方案例子
                </div>
                <div className={'Navbar_text'} onClick={() => scrollToAnchor('InformationTechnology')}>資訊科技知識
                </div>
                <div className={'Navbar_text'} onClick={() => scrollToAnchor('ContactUs')}>聯絡我們
                </div>
                {/* <img className={'logo'} src={sec1Img}/> */}
                <img className={'close'} src={close} onClick={() => check()}/>
            </div>
        </div>
    }

    const Sec1 = () => {
        const [isMobile, setIsMobile] = useState(false)
        useEffect(() => {
            if (window.innerWidth < 768) {
                setIsMobile(true)
            }
        }, [])
        useEffect(() => {
            window.particleImageDisplay("particle-image")
        }, [isMobile])
        return <div>
            <div className="container wrapper section1">
                <div className='left-text-box'>
                    {/* <div className='p'>
                        資訊價值 科技創造
                    </div>
                    <div>
                        您的科技夥伴 帶您走到最前
                    </div> */}
                    <img src={sec1Ellipses} className='bg-img'/>
                    <svg class="intro go pc">
                        <text text-anchor="start" x="100" y="50" class="text text-stroke" clip-path="url(#text1)">資訊價值
                            科技創造
                        </text>
                        <text text-anchor="start" x="10" y="150" class="text text-stroke" clip-path="url(#text2)">您的科技夥伴
                            帶您走到最前
                        </text>
                        <text text-anchor="start" x="100" y="50" class="text text-stroke text-stroke-2"
                              clip-path="url(#text1)">資訊價值 科技創造
                        </text>
                        <text text-anchor="start" x="10" y="150" class="text text-stroke text-stroke-2"
                              clip-path="url(#text2)">您的科技夥伴 帶您走到最前
                        </text>
                        <defs>
                            <clipPath id="text1">
                                <text text-anchor="start" x="100" y="50" class="text">資訊價值 科技創造</text>
                            </clipPath>
                            <clipPath id="text2">
                                <text text-anchor="start" x="10" y="150" class="text">您的科技夥伴 帶您走到最前</text>
                            </clipPath>
                        </defs>
                    </svg>
                    <svg class="intro go app">
                        <text text-anchor="start" x="60" y="40" class="text text-stroke" clip-path="url(#text3)">資訊價值
                            科技創造
                        </text>
                        <text text-anchor="start" x="10" y="100" class="text text-stroke" clip-path="url(#text4)">您的科技夥伴
                            帶您走到最前
                        </text>
                        <text text-anchor="start" x="60" y="40" class="text text-stroke text-stroke-2"
                              clip-path="url(#text3)">資訊價值 科技創造
                        </text>
                        <text text-anchor="start" x="10" y="100" class="text text-stroke text-stroke-2"
                              clip-path="url(#text4)">您的科技夥伴 帶您走到最前
                        </text>
                        <defs>
                            <clipPath id="text3">
                                <text text-anchor="start" x="60" y="40" class="text">資訊價值 科技創造</text>
                            </clipPath>
                            <clipPath id="text4">
                                <text text-anchor="start" x="10" y="100" class="text">您的科技夥伴 帶您走到最前</text>
                            </clipPath>
                        </defs>
                    </svg>
                    <svg class="intro go h5">
                        <text text-anchor="start" x="30" y="40" class="text text-stroke" clip-path="url(#text5)">資訊價值
                            科技創造
                        </text>
                        <text text-anchor="start" x="0" y="70" class="text text-stroke" clip-path="url(#text6)">您的科技夥伴
                            帶您走到最前
                        </text>
                        <text text-anchor="start" x="30" y="40" class="text text-stroke text-stroke-2"
                              clip-path="url(#text5)">資訊價值 科技創造
                        </text>
                        <text text-anchor="start" x="0" y="70" class="text text-stroke text-stroke-2"
                              clip-path="url(#text6)">您的科技夥伴 帶您走到最前
                        </text>
                        <defs>
                            <clipPath id="text5">
                                <text text-anchor="start" x="30" y="40" class="text">資訊價值 科技創造</text>
                            </clipPath>
                            <clipPath id="text6">
                                <text text-anchor="start" x="0" y="70" class="text">您的科技夥伴 帶您走到最前</text>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className='right-img'>
                    <div id="particle-image"
                         data-params-src={isMobile ? '/particle-image-params-mobile.json' : '/particle-image-params.json'}></div>
                </div>

            </div>
        </div>
    }
    const Sec2 = () => {
        return <div className={'wrapper section2'} id='CloudServices'>


            <img className={'Sec2_bg'} src={sec2Bg}/>
            <div className="textCenter main-box">
                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    options={particlesOptions}
                    style={{width: '100%', height: '100%', position: 'absolute', left: 0, top: 0}}
                />
                <div className='container'>
                    <div className="Sec2_text text-2xl pt-8 lg:text-3xl">
                        <div>提供雲端軟件設計開發</div>
                        <div>包括雲端應用、網頁設計、手機程式等多種雲端解決方案</div>
                    </div>
                    <div className='img-center blockCenter'>
                        <img className={'Sec2_img blockCenter'} src={sec2Img}/>
                        <img className={'Sec2_img_txt blockCenter'} src={sec2ImgTxt}/>
                    </div>
                    <div className="Sec2_text one blockCenter text-xl lg:text-2xl">
                        雲端應用程序優勢在於節省成本、提高效率和可靠性，並提供更好的擴展性和安全性。使用雲端服務可避免企業自身擁有和維護昂貴的IT設施和人力成本，也可以讓企業更專注於其核心業務。
                    </div>
                    <div className='bottom-img-box'>
                        <img src={sec2Img1} onClick={() => alert('coming soon')}/>
                        <img src={sec2Img2} onClick={() => alert('coming soon')}/>
                    </div>
                </div>
            </div>
            <img src={sec3topimg} width={'100%'} style={{position: 'absolute', left: 0, bottom: 0}}/>
        </div>
    }
    const Sec3 = () => {
        return (<div className={"wrapper section3"} id="InformationTechnology">
            {/* <img className={'Sec3_bg'} src={sec3Bg}/> */}
            <div className="main-box">
                <div className="container textCenter txtbox">
                    <img src={sec3Text} className="img1"/>
                    {/* <img src={sec3Text4}  className='img2'/>
                    <div className='blue-line' ></div> */}
                    <div className="title-sub img2">
                        第三代互聯網融合方案
                        <div className="blue-line blockCenter"></div>
                    </div>
                    <div className="whitetxt">
                        互聯網的科技革命正在快速演進
                        <br/>
                        一個更加<b>開放，公平和安全</b>的網絡
                    </div>
                    {/* <img src={sec3Text3} className='img3'/>
                    <img src={sec3Text2} className='img4'/> */}
                </div>
                <div className="textCenter main-img-box">
                    <div className="topbox">
                        <div className="ball-left ball">
                            <img src={secImg2}/>
                            <span>個體價值</span>
                        </div>
                        <div className="ball-right ball">
                            <img src={secImg3}/>
                            <span>數據資產化</span>
                        </div>
                        <img src={secImg4} className="center center-img"/>
                    </div>
                    <div className="ball-center ball blockCenter">
                        <img src={secImg5}/>
                        <span>信任數據</span>
                    </div>
                </div>
                <div className="title-sub">
                    <p className="title-center">方案技術</p>
                    <div className="blue-line blockCenter"></div>
                </div>

                <div className="container textCenter">
                    <div className="stone-box">
                        <div className="item">
                            <div className="p1">分散式自治組織</div>
                            <div className="p2">DAO</div>
                        </div>
                        <div className="item">
                            <div className="p1">去中心化金融</div>
                            <div className="p2">DeFi</div>
                        </div>
                        <div className="item">
                            <div className="p1">去中心化應用</div>
                            <div className="p2">DApp</div>
                        </div>
                    </div>
                    <div className="square-box">
                        <img src={sec3Square}/>
                    </div>
                    <div className="person-box">
                        <div className="left">
                            <img src={sec3Img9} className="left-img"/>
                        </div>
                        <div className="right">
                            <div className="title-sub">
                                港府支持與推動
                                <div className="blue-line blockCenter"></div>
                            </div>
                            <div className="sec3Text">
                                <p>
                                    行政長官李家超表示，為使香港成為虛擬資產企業最佳立足點，政府需要為市場提供適切監管，釋放Web3及相關技術潛力。將於6月實施的發牌制度為虛擬資產交易所提供一定程度的市場認可，確保市場穩健有序發展，保障投資者。
                                </p>
                                <p>
                                    財政司陳茂波：香港是「國際優質虛擬資產企業」的最佳立足點
                                </p>
                                <p>
                                    習近平主席在14屆全國人大一次會議發表的重要講話指出，要着力提升科技自立自強能力，不斷壯大國家的科技實力。國家十四五規劃明確支持香港建設國際創新科技中心。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
    const Sec4 = () => {
        return (<div className={"wrapper section4"} id="CorporateCulture">
            <img className="Sec4_bg2" src={sec4BG}/>
            <div className="main-box">
                <Swiper
                    modules={[Pagination, Mousewheel]}
                    className="swiper-box"
                    direction={"vertical"}
                    loop={false}
                    mousewheel={{releaseOnEdges: true}}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    pagination={{
                        el: ".swiper-pagination", type: "bullets", clickable: true,
                    }}
                >
                    <SwiperSlide>
                        <div className="container flex flex-col justify-center items-center">
                            <div className="title-gray-box">
                                <div className="title">公司文化</div>
                                <div className="blue-line"></div>
                            </div>

                            <div className="box">
                                我們重視專業誠信、團隊合作和結果導向的價值觀
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container flex flex-col justify-center items-center">
                            <div className="title-gray-box">
                                <div className="title">願景使命</div>
                                <div className="blue-line"></div>
                            </div>
                            <div className="box">
                                成為資訊科技趨勢的引領者 為未來數字化社會做出貢獻
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container flex flex-col justify-center items-center">
                            <div className="title-gray-box">
                                <div className="title">核心價值</div>
                                <div className="blue-line"></div>
                                <div className="desc" style={{color: "#000000"}}>
                                    利用資訊科技 實現價值創造
                                </div>
                            </div>
                            <div className="box pd0">
                                <p>提高企業的生產效率和質量 解決問題根源以及核心所在</p>
                                <p>
                                    重視企業道德，堅持誠信經營，遵守法律法規和商業倫理規範
                                </p>
                                <p>
                                    維護客戶利益，與客戶之間的交流和溝通緊密清晰
                                    <br/>
                                    確保客戶明白技術方案和細節
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container flex flex-col justify-center items-center">
                            <div className="title-gray-box">
                                <div className="title">團隊協調</div>
                                <div className="blue-line"></div>
                            </div>
                            <div className="box pd0">
                                <p>
                                    不斷地追求<span>突破</span>和<span>創新</span>
                                    ，提高產品或服務品質
                                </p>
                                <p>
                                    提倡自主學習，透過小型專項和項目，<br></br>
                                    可以提高學習者的<span>實踐能力</span>和
                                    <span>問題解決能力</span>，<br></br>從而推進學習和成長
                                </p>
                                <p>
                                    成員回顧及分享過程和遇到的問題及解決方案<br></br>
                                    激勵及促進成員的<span>積極性</span>和<span>創造力</span>
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <div class="swiper-pagination"></div>
                </Swiper>
            </div>
            <div className="video-box">
                {/* 如果是视频用用下面注释的代码，图片仅做效果 */}
                <video
                    autoPlay
                    loop
                    muted
                    width="100%"
                    webkit-playsinline="true"
                    playsInline={true}
                    style={{objectFit: "cover", height: "100%"}}
                >
                    <source src={videoMp4} type="video/mp4"/>
                </video>
                {/* <img src={sec4Bg} width={'100%'}/> */}
            </div>
        </div>);
    }
    const Sec5 = () => {
        return <div className={'wrapper section5'} id='FundingPlanning'>
            {/* <img src={sec5Bg} className='bg-img'/> */}
            <div className='main-box'>
                <div className='title-gray-box my-[128px]'>
                    <div className='title'>資助規劃</div>
                    <div className='blue-line'></div>
                    <div className='desc'>提供各種資助申請咨詢和申請服務，協助您取得資金支持</div>
                </div>
                <div className='con-box'>
                    <div className="wrapper">
                        <div className='item'>
                            <div className='title'><img src={sec5Img1} className='icon'/>科技券</div>
                            <div className='desc'>
                                科技券是政府提供的資助計劃，支援香港中小企引入<span>科技和提升業務效率</span>。申請者最高可獲港幣<b>60</b>萬元的資助，用於相關科技產品或服務的購買及導入。
                            </div>
                        </div>
                    </div>
                    <div className="wrapper">
                        <div className='item'>
                            <div className='title'><img src={sec5Img3} className='icon'/>大灣區創業之星計劃</div>
                            <div className='desc'>
                                大灣區創業之星計劃是支援香港、澳門及珠三角地區創業者的資助計劃，提供<span>創業培訓、融資支援及市場推廣</span>等服務。申請者可獲最高港幣<b>170</b>萬元的綜合資助和底息創意融資。
                            </div>
                        </div>
                    </div>
                    <div className="wrapper">
                        <div className='item'>
                            <div className='title'><img src={sec5Img4} className='icon'/>BUD 專項基金</div>
                            <div className='desc'>
                                BUD專項基金是香港特別行政區政府為支援本地中小企業融合科技而設立的資助計劃，旨在提高企業<span>競爭力和創新能力</span>。申請者可獲港幣<b>700</b>萬元的資助，用於開發和應用科技項目。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    const Sec6 = () => {
        return <div className={'wrapper section5'} id='ExampleOfScheme'>
            <div className='main-box'>
                <div className='title-gray-box my-[128px]'>
                    <div className='title'>產品服務考量</div>
                    <div className='blue-line'></div>
                    <div className='desc'>多角度全面分析 令成品更完美更優秀</div>
                </div>
                <div className='con-service-box'>
                    <div className='item'>
                        <img src={sec6Img1} className='icon'/>
                        <div className='title'>結果成效</div>
                    </div>
                    <div className='item'>
                        <img src={sec6Img2} className='icon'/>
                        <div className='title'>明確目標</div>
                    </div>
                    <div className='item'>
                        <img src={sec6Img3} className='icon'/>
                        <div className='title'>競爭分析</div>
                    </div>
                    <div className='item'>
                        <img src={sec6Img4} className='icon'/>
                        <div className='title'>需求變化</div>
                    </div>
                    <div className='item'>
                        <img src={sec6Img5} className='icon'/>
                        <div className='title'>企業經營</div>
                    </div>
                </div>
                <img src={sec6Img6} className='service-main-img'/>
            </div>
        </div>
    }
    const Sec7 = () => {
        return <div className={'wrapper section5'}>
            <div className='main-box'>
                <div className='title-gray-box my-[128px]'>
                    <div className='title'>One Take 開發流程八步曲</div>
                    <div className='blue-line'></div>
                    <div className='desc'>步步與你緊密聯繫</div>
                </div>
                <div className='omg-box container'>
                    <div className='item'>
                        <img src={sec6Img7}/>
                        <div className='title one'>
                            <b>1</b>了解<span>您</span>的需求
                        </div>
                        <div className='sub one'>提供專業意見及解決方案</div>
                        <div className='title two'>
                            <b>2</b>確認報價
                        </div>
                        <div className='sub two'><span>我們</span>會提供成本效益最高的方案</div>
                    </div>
                    <div className='item pc'>
                        <img src={sec6Img7}/>
                        <div className='title one'>
                            <b>5</b>為<span>您</span>選擇開發方法
                        </div>
                        <div className='sub one'>配合合適您的方法（敏捷式／瀑布式）進行開發</div>
                        <div className='title two'>
                            <b>6</b>產品測試
                        </div>
                        <div className='sub two'><span>我們</span>建立測試環境進行修改及驗收</div>
                    </div>
                    <div className='item'>
                        <img src={sec6Img7}/>
                        <div className='title one'>
                            <b>3</b>為<span>您</span>度身訂
                        </div>
                        <div className='sub one'>做仔細進行方案設計、製作項目藍圖</div>
                        <div className='title two'>
                            <b>4</b>確認文檔
                        </div>
                        <div className='sub two'><span>我們</span>確保與您保持目標的一致性</div>
                    </div>
                    <div className='item h5'>
                        <img src={sec6Img7}/>
                        <div className='title one'>
                            <b>5</b>為<span>您</span>選擇開發方法
                        </div>
                        <div className='sub one'>配合合適您的方法（敏捷式／瀑布式）進行開發</div>
                        <div className='title two'>
                            <b>6</b>產品測試
                        </div>
                        <div className='sub two'><span>我們</span>建立測試環境進行修改及驗收</div>
                    </div>
                    <div className='item'>
                        <img src={sec6Img7}/>
                        <div className='title one'>
                            <b>7</b>為<span>您</span>優化、維護產品
                        </div>
                        <div className='sub one'>進一步強化系統功能、確保系統順暢穩定</div>
                        <div className='title two'>
                            <b>8</b>技術支援
                        </div>
                        <div className='sub two'><span>我們</span>會盡力解決您的疑難</div>
                    </div>
                </div>
            </div>
        </div>
    }
    const Sec8 = () => {
        return <div className={'wrapper section8 mt-8 md:mt-16'}>
            <div className='main-box'>
                <div className='title-gray-box'>
                    <div className='title'>與全球頂級科技企業合作</div>
                    <div className='blue-line'></div>
                </div>
                <div className='con-box'>
                    <view className='item'>
                        <img src={sec8img1}/>
                    </view>
                    <view className='item'>
                        <img src={sec8img2}/>
                    </view>
                    <view className='item'>
                        <img src={sec8img3}/>
                    </view>
                    <view className='item'>
                        <img src={sec8img4}/>
                    </view>
                    <view className='item'>
                        <img src={sec8img5}/>
                    </view>
                </div>
            </div>
            <img src={sec8img6} className='bottom-img'/>
        </div>
    }
    const Sec9 = () => {
        return <div className={'wrapper section8 section9'} id='ContactUs'>
            <div className='main-box'>
                <div className='title-gray-box'>
                    <div className='title'>聯絡我們</div>
                    <div className='blue-line'></div>
                    <div className='desc'>您有想法? 事不宜遲 立即與我們商洽！</div>
                </div>


                <div className="my-4 md:my-8 xl:my-16">
                    <button
                        onClick={() => {
                            var link =
                                "mailto:thefirstcakehk@gmail.com" +
                                "?cc=" +
                                "&subject=" +
                                encodeURIComponent("") +
                                "&body=" +
                                "";
                            window.location.href = link;
                        }}
                        className="rounded-full my-6 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out inline-flex"
                    >
                        <img className="w-8 md:w-12" src={gmailIcon}/>
                        <p className="pl-4 self-center  text-md md:text-2xl text-[#50698B] font-bold">info@onetakesolutions.com.hk</p>
                    </button>
                </div>


            </div>
            <div className="relative">
                <img src={sec9img1} className='w-full h-[140px] md:h-[180px] xl:h-[250px]'/>
                <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-row justify-center">
                    <div>
                        <div className='font-bold text-md md:text-xl'>開發策略諮詢</div>
                        <button
                            onClick={() => {
                                window.open("https://wa.me/85290306786?text=你好，我從網站上看到你們提供的服務！有興趣了解更多！", "_blank", "noreferrer");
                            }}
                            className="rounded-full my-6 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out inline-flex"
                        >
                            <img className="w-8 md:w-12" src={whatsappIcon}/>
                            <p className="pl-4 self-center text-md md:text-2xl text-[#50698B] font-bold">+852 9030
                                6786</p>
                        </button>
                    </div>
                    <div className="w-16 lg:w-32">
                    </div>
                    <div className="">
                        <div className='font-bold text-md md:text-xl'>資助相關諮詢</div>
                        <button
                            onClick={() => {
                                window.open("https://wa.me/85266850410?text=你好，我從網站上看到你們提供的服務！有興趣了解更多！", "_blank", "noreferrer");
                            }}
                            className="rounded-full my-6 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out inline-flex"
                        >
                            <img className="w-8 md:w-12" src={whatsappIcon}/>
                            <p className="pl-4 self-center text-md md:text-2xl text-[#50698B] font-bold">+852 6685
                                0410</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }
    const Sec10 = () => {
        return <div className={'wrapper section8 section10'}>
            <div className='main-box container'>
                <div className='row-box'>
                    <div className='col'>
                        <img src={logo} className='logo'/>
                        <div
                            className='info'>壹剔過有限公司提供細緻、高效、穩定且人性化的軟件設計開發服務，不論軟件規模大小皆能提供服務。團隊擁有豐富經驗和技術知識，以客戶需求為出發點，助客戶成為數碼化時代的先鋒。
                        </div>
                        <div className='info'>Unit A, 2/F, 94-96 How Ming Street, Hung To Centre, Kwun Tong, Hong Kong
                        </div>
                    </div>
                    <div className='menu'>
                        <div className='item'>
                            <div className='name'>SECTION</div>
                            <ul>
                                <li>
                                    <div className='link' onClick={() => {
                                        window.location.hash = 'ContactUs'
                                    }}>About Us
                                    </div>
                                </li>
                                <li>
                                    <div className='link' onClick={() => {
                                        window.location.hash = 'CloudServices'
                                    }}>Business Scope
                                    </div>
                                </li>
                                <li>
                                    <div className='link' onClick={() => {
                                        window.location.hash = 'ExampleOfScheme'
                                    }}>Why Us Work
                                    </div>
                                </li>
                                <li>
                                    <div className='link' onClick={() => {
                                        window.location.hash = 'ContactUs'
                                    }}>Flow
                                    </div>
                                </li>
                                <li>
                                    <div className='link' onClick={() => {
                                        window.location.hash = 'ContactUs'
                                    }}>Q & A
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='item'>
                            <div className='name'>REFERENCE</div>
                            <ul>
                                <li>
                                    <div className='link' onClick={() => {
                                        window.location.hash = ''
                                    }}>Case examples
                                    </div>
                                </li>
                                <li>
                                    <div className='link' onClick={() => {
                                        window.location.hash = ''
                                    }}>Careers
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='item'>
                            <div className='name'>LEGAL</div>
                            <ul>
                                <li>
                                    <div className='link' onClick={() => {
                                        window.location.hash = ''
                                    }}>Privacy Policy
                                    </div>
                                </li>
                                <li>
                                    <div className='link' onClick={() => {
                                        window.location.hash = ''
                                    }}>Terms & Conditions
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='copyright'>Copyright © 2023 One Take Solutions Limited. All rights reserved.</div>
            </div>
        </div>
    }
// export default () => {

//     return <div className={'desktopPage'}>
//         <Header/>
//         <Sec1></Sec1>
//         <Sec2></Sec2>
//         <Sec3></Sec3>
//         <Sec4></Sec4>
//         <Sec5></Sec5>
//         <Sec6></Sec6>
//         <Sec7></Sec7>
//         <Sec8></Sec8>
//         <Sec9></Sec9>
//         <Sec10></Sec10>
//     </div>
// }


    const scrollToAnchor = (anchorName) => {
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) {
                anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
            setisShow(false)
        }
    }

    const [isShow, setisShow] = useState(false)
    const check = () => {
        setisShow(!isShow)
    }
    /*useEffect(() => {
        window.particleImageDisplay("particle-image");
    }, [])*/
    return <div className={'desktopPage'}>
        <Header/>
        <Sec1></Sec1>
        <Sec2></Sec2>
        <Sec3></Sec3>
        <Sec4></Sec4>
        <Sec5></Sec5>
        <Sec6></Sec6>
        <Sec7></Sec7>
        <Sec8></Sec8>
        <Sec9></Sec9>
        <Sec10></Sec10>
    </div>
}
